import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'Probiz/saleorder',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    redirectTo: 'Probiz/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'Probiz/authen',
    loadChildren: () => import('./views/authen/authen.module').then(m => m.AuthenModule)
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'Probiz',
    component: DefaultLayoutComponent,
    data: {
      title: 'Probiz'
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: 'purchase',
        loadChildren: () => import('./views/purchase/purchase.module').then(m => m.PurchaseModule)
      }
      ,
      {
        path: 'account',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'stock',
        loadChildren: () => import('./views/stock/stock.module').then(m => m.StockModule)
      },
      {
        path: 'sale',
        loadChildren: () => import('./views/sale/sale.module').then(m => m.SaleModule)
      },
      {
        path: 'master',
        loadChildren: () => import('./views/master/master.module').then(m => m.MasterModule)
      },
      {
        path: 'list',
        loadChildren: () => import('./views/list/list.module').then(m => m.ListModule)
      },
      {
        path: 'product',
        loadChildren: () => import('./views/product/product.module').then(m => m.ProductModule)
      }
      ,
      {
        path: 'saleorder',
          loadChildren: () => import('./views/sale-order/sale-order.module').then(m => m.SaleOrderModule)
      },
      {
        path: 'report',
          loadChildren: () => import('./views/report/report.module').then(m => m.ReportModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
