import {Injectable} from '@angular/core';
import {SaveImageReuqest, SavePurchaseRequest, PriceListReportRequest, PriceList, SavePriceListReportRequest, SaveProductRequest, SaveDocumentRequest, PurchaseArchive, PurchaseArchiveRequest, SavePurchaseArchiveRequest, DeletePurchaseArchiveRequest, UpdateCustomerShowingRequest} from './InputObject';
import {GlobalDataService} from '../globaldata.service';
import {PostService} from './PostService';

@Injectable()
export class InputService {

    inputtUrl: string;

    constructor(private gd: GlobalDataService, private ps: PostService) {
        this.inputtUrl = this.gd.shareObj['InputApi'];
    }


    getPriceList(req: PriceListReportRequest) {
        return this.ps.post(this.inputtUrl + "getPriceList", req);
    }


    savePriceList(req: SavePriceListReportRequest) {
        return this.ps.post(this.inputtUrl + "savePriceList", req);
    }

    saveProduct(req: SaveProductRequest) {
        return this.ps.post(this.inputtUrl + "saveProduct", req);
    }

    getDocuments() {
        return this.ps.post(this.inputtUrl + "getDocuments", {});
    }

    saveDocuments(req: SaveDocumentRequest) {
        return this.ps.post(this.inputtUrl + "saveDocuments", req);
    }

    savePurchasepointConfig(req: SavePurchaseRequest) {
        return this.ps.post(this.inputtUrl + "savePurchasepointConfig", req);
    }


    getPurchasepointAcrhive(req: PurchaseArchiveRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.inputtUrl + "getPurchaseArchive", req);
    }

    savePurchasepointAcrhive(req: SavePurchaseArchiveRequest) {
        return this.ps.post(this.inputtUrl + "SavePurchaseArchive", req);
    }

    deletePurchasepointAcrhive(req: DeletePurchaseArchiveRequest) {
        return this.ps.post(this.inputtUrl + "DeletePurchaseArchive", req);
    }

    updateProductShowing(req: UpdateCustomerShowingRequest) {
        return this.ps.post(this.inputtUrl + "updateProductShowing", req);
    }
    saveImageReuqest(req: SaveImageReuqest) {
        return this.ps.post(this.inputtUrl + "saveImageFile", req);
    }
    saveProductNew(req: any) {
        return this.ps.post(this.inputtUrl + "saveProductNew", req);
    }
    saveProductEdit(req: any) {
        return this.ps.post(this.inputtUrl + "saveProductEdit", req);
    }
    saveDeleteProduct(req: any) {
        return this.ps.post(this.inputtUrl + "saveDeleteProduct", req);
    }
}