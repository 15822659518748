import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {ListRoutingModule} from './list-routing.module';
import {SignaturePadModule} from 'angular2-signaturepad';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CalendarModule} from 'primeng/calendar';
import {TabMenuModule} from 'primeng/tabmenu';
import {PrintingModule} from '../../components/printing/printing.module';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {ListComponent} from './list.component';
import {GTStockComponent} from '../stock/gt-stock.component';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';

@NgModule({
    imports: [
        DropdownModule,
        FormsModule,
        CommonModule,
        TableModule,
        ListRoutingModule,
        SignaturePadModule,
        ConfirmDialogModule,
        CalendarModule,
        TabMenuModule,
        PrintingModule,
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        ZXingScannerModule, ButtonModule, InputTextModule
    ],
    declarations: [
        ListComponent
    ],
    exports: [
        ListComponent
    ],
    providers: []
})
export class ListModule {}
