/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

declare let x_tion: any;

import { PostObject } from './PostObject';


export class BaseUser extends PostObject {
    id: number;
    profile_id: number;
    company_id: number;
    print_form: number;
    company_code: string;
    login: string;
    profile_name: string;
    name: string;
    user_id: number;
}


export class UpdateOfflineUserRequest {
    id: number;
    password: string;
}

export class CompanyRequest {
    company_id: number;
}


export class UserRequest {
    username: string;
}

export class SoPrintRequest {
    sonumber: string;
}


export class Company extends PostObject {
    id: any;
    name: any;
    code: string;
    address: string;
    phone: string;
    fax: string;
    description: string;
}


export class ProductFilterRequest extends PostObject {
    name: string;
}


export class ProductCategFilterRequest extends PostObject {
    name: string;
}


export class Product {
    id: number;
    name: string;
    pcode: string;
    pid: number;
    pname: string;
    uom_id: number;
    uom_name: string;
    unit_name:string;
    unit: string;
    cogs_iv: number;
    fullname: string;
    price1: number;
    price2: number;
    price3: number;
    price4: number;
    price5: number;
    barcode: string;
    top_stock: number;
    top_stock2: string;
    zone_name: number;
    zone_name2: string;
    parent_id:any;
    tax_id:any;
    categ_id:number;
    product_name: string;
    product_model: string;
    whipping: number;
    image: string;
    total:number;
    
}

export class ProductCateg {
    id: number;
    name: string;
}

export class Contact {
    id: number;
    code: string;
    first_name: string;
    last_name: string;
    name: string;
}



export class Employee {
    id: number;
    name: string;
}


export class Location {
    id: number;
    name: string;
}


export class ProductRequest extends PostObject {
    barcode: string;
    code: string;
    name: string;
    productIds: number[];
    productCategIds: number[]
}

export class ProductPrinting {
    id: number;
    barcode: string;
    code: string;
    name: string;
    image: string;
    selected: boolean = false;
    price1: number;
    price2: number;
    qty: number = 1;
    size: string;
    unit: string;
    description: string;
    showcustomer: boolean;
}

export class ProductPrintingOdoo {
    id: number;
    barcode: string;
    code: string;
    name: string;
    image: string;
    selected: boolean = false;
    list_price: number;
    weight: number;
    description_sale: string;
    showcustomer: boolean;
    date_order: string;
    customername: string;
    mobile: string;
    address: string;
    default_code: string;
}

export class UOM {
    id: number;
    name: string;
}

export class Color {
    id: number;
    code: string;
    type: string;
}

export class ProductGroup {
    id: number;
    name: string;
}

export class ProductBrand {
    id: number;
    name: string;
}

export class Size {
    id: number;
    code: string;
    type: string;
}



export class Account {
    id: number;
    name: string;
    code: string;
    company_id: number;
}


export class AccountTaxRate {
    id: number;
    name: string;
    code: string;
}

export class NfApiRequest {
    id: number;
    method: string;
    params: any[];
}


export class ProductIdsRequest extends PostObject {
    productCodes: string[];
}

export class ProductIds extends PostObject {
    product_id: number;
    qty: number;
    code: string;
}

export class getUsernameByCompanyIdRequest {
    company_id: number;
}


export class Context {
    token: string;
    user_id: string;
    company_name: string;
    package_name: string;
    package: string;
    user_name: string;
    dbname: string;
    company_id: string;
}



export class OfflineObject {
    id: number;
    number: string;
    name: string;
    date: Date;
    doc_date: Date;
    ref: string;
    state: string;
    amount_subtotal: number;
    amount_tax: number;
    amount_total: number;
    currency_id: number;
    uuid: string;
    data: string;
    sent_to_nf: any;
    result: string;
    result_date: Date;
    state_update2: Date;
    state_update3: Date;
    sent_to_request_limit: boolean;
    remark: string;
    sale_data2: string;
    head: string;
    lines: string;
    company_id: number;
    sequence_id: number;
    model: string;
    sequence_code: string;
    line: string;
    constructor() {
        this.sent_to_nf = false;
        this.date = new Date();
        this.doc_date = new Date();
    }


    getDate() {
        if (this.date && this.date instanceof Date) {
            return this.date;
        }
        if (this.date) {
            this.date = x_tion.gd.newDate(this.date);
            return this.date;
        }
        return null;
    }


    getDocDate() {
        if (this.doc_date && this.doc_date instanceof Date) {
            return this.doc_date;
        }
        if (this.doc_date) {
            this.doc_date = x_tion.gd.newDate(this.doc_date);
            return this.doc_date;
        }
        return null;
    }


    getSendToNF() {
        if (this.sent_to_nf && typeof this.sent_to_nf == "boolean") {
            return this.sent_to_nf;
        }
        if (this.sent_to_nf) {
            if (this.sent_to_nf === "true" || this.sent_to_nf === "1") {
                this.sent_to_nf = true;
                return this.sent_to_nf;
            }
        }
        this.sent_to_nf = false;
        return this.sent_to_nf;
    }



}

export class Sequence {
    id: number;
    name: string;
}



export class TaxRate {
    id: number;
    name: string;
}

export class AccountTrack {
    id: number;
    name: string;
}

export class Currency {
    id: number;
    name: string;
}

export class Budget {
    id: number;
    name: string;
}


export class PaymentTerm {
    id: number;
    name: string;
}

export class Address {
    id: number;
    name: string;
}


export class PriceList {
    id: number;
    name: string;
}


export class PurchaseType {
    id: number;
    name: string;
}


export class PurchaseRef {
    id: number;
    name: string;
}

export class ShippingMethod {
    id: number;
    name: string;
}


export class Truck {
    id: number;
    name: string;
}


export class Driver {
    id: number;
    name: string;
}

export class PrintingTemplate {
    id: number;
    name: string;
}
export class runNumberDoc {
    prefix: string;
    runno: string;
}
export class runDoc {
    company_id: any;
    prefix: string;
}

export class Bank {
    id: number;
    name: string;
}
export class pwc_log {
  id: number;
  approve_by_id: any;
  approve_by_name: any;
  remark: string;
  state: string;
  type_req: string;
  user_id: any;
  user_name;
}
export class Category {
  id: number;
  parent_id: number;
  code: string;
  name: string;
}
export class OrderLine {
    contact_id: number;
    currency_id: number;
    ref: string;
    lines: Line[];
    currency_rates: any[];
    type: string;
    register_id: number;
    company_id: number;
    payment_method_id: number;
    payment_type: string;
    user_id: number;
    gotmoney: number;
    changemoney: number;
    discount_amount_total: number;
    price_list_id: number;
    other_info: string;
    sequence_id: number;
    date: any;
    due_date: any;
    sale_method_id: number;
    other_info_id: number;
    seller_id: number;
    tax_type: string;
    bill_address_id: number;
    ship_address_id: number;
    shipping_address: string;
    
    score_cm1: number;
    score_cm3: number;
    score_total: number;
    amount_cm1: number;
    amount_cm3: number;
}
export class Line {
    product_id: number;
    description: string;
    unit_price: number;
    qty: number;
    uom_id: number;
    location_id: number;
    discount_amount: number;
    currency_id: number;
}
export class ProductPOS {
    pid: number;
    pcode: string;
    pname: string;
    pname2: string;
    pname3: string;
    unit: string;
    price1: number;
    price2: number;
    price3: number;
    price4: number;
    price5: number;
    vatrate: number;
    barcode: string;
    tax_id: number;
    uom_id: number;
    categ_id: number;
    image: string;
    is_warehouse: boolean;
    is_cm: string;
    w1: boolean;
}

export class OfflineSaveBillWithNumberRequest {
    number: string;
    sale_data: string;
}
export class OfflineSaveBillRequest {
    currency_id: number;
    company_id: number;
    user_id: number;
    company_code: string;
    type: string;
    offline_sequence_code: string;
    contact_id: number;
    amount_total: number;
    contact_name: string;
    sale_data2: string;
}
export class Orderd {
    code: string;
    product: ProductPOS;
    location: string;
    unit: string;
    price: any;
    qty: number;
    vat: number;
    summary: number;
    dcorderd: number;
    barcode: string;
    tax_id: number;
    uom_id: number;
    discount: number;
    balance: number;
    cost: number;
    isfreeItem: boolean;
    dccampaign: number;
    other_info: number;
    location_name: string;
    accounttaxrate: AccountTaxRate;
    is_warehouse: boolean;
    constructor(){
        this.is_warehouse =false;
    }
}
export class BillDailyReport extends PostObject {
    saledate: any;
    number: string;
    contactname: any;
    companyname: any;
    paid_type: any;
    sale: any;
    tax: any;
    cost: any;
    profit: any;
    billcount: any;
    id: number;
    sent_to_nf: boolean;
    username: string;
    state2: string;
    remark: string;
    state: string;
    state_update2:number;
    sale_data:any;
    lines:OrderdLine[]=[];
    done_state: string;
    numrow:number;
    seller: string;
}
export class BillDetailDailyReportRequest {
    type: string;
    sdate: string;
    edate: string;
    company_id: number;
    billno: string;
    constructor() {
        this.billno = "";
    }
    productIds: number[];
    productCategIds: number[]
    onlyDraft: boolean;
    contact_name: string;
    amount_total: number;
}
export class OrderdLine {
    product_code: string;
    product_name: string;
    qty: number;
    description: string;
    product_model: string;
    unit_price: number;
    image: string;
    description2: string[];
}
export class SaveVoidRequestRequest {
    company_id: number;
    create_uid: number;
    related_id: string;
    remark: string;
    type: string;
    type_req: string;
    user_id: number;
    write_uid: number;
    number: string;
    state: string;
    id: number;
    sale_state: string;
    sale_state2: string;
}
export class CreateSoTaskRequest{
    id: number;
    number: string;
}
export class SaveDoneStateRequest{
    order_id: number;
}
export class ProductNF{
    product_id: number;
    product_code: string;
    product_name: string;
    gcode: string;
    gname: string;
    unit: string;
    categ_id: number;
    categ_parent_id: number;
    product_name2: string;
    state: string;
    image: string;
    price1: number;
    price_list_item_id: number;
    active:boolean;
}