import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PrintingTemplateComponent } from './printing-template.component';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ModalModule.forRoot(),
        DropdownModule
    ],
    declarations: [
        PrintingTemplateComponent
    ],
    exports: [
        PrintingTemplateComponent
    ]
})
export class PrintingModule { }
