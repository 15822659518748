import { Injectable } from '@angular/core';
import { SaveDoneStateRequest,CreateSoTaskRequest,SaveVoidRequestRequest,BillDetailDailyReportRequest,OfflineSaveBillWithNumberRequest,OfflineSaveBillRequest,Company, ProductFilterRequest, ProductCategFilterRequest, getUsernameByCompanyIdRequest, CompanyRequest, ProductRequest, NfApiRequest, ProductIdsRequest, UserRequest, SoPrintRequest } from './BaseObject';
import { GlobalDataService } from '../globaldata.service';
import { PostService } from './PostService';

@Injectable()
export class BaseService {

    baseUrl: string;
    mobileUrl: string;
    posApi: string;
    autocompleteApi: string;
    offlineUrl: string;

    constructor(private gd: GlobalDataService, private ps: PostService) {
        this.baseUrl = this.gd.shareObj['BaseApi'];
        this.mobileUrl = this.gd.shareObj['AppMobileApi'];
        this.autocompleteApi = this.gd.shareObj['AutoCompleteApi'];
        this.posApi = this.gd.probizURL + "/json_rpc";
        this.offlineUrl = this.gd.shareObj['POSOfflineApi'];
    }

    getCompany() {
        let company_id = 1;
        let multicompany = 1;
        let companyRequest: CompanyRequest = new CompanyRequest();
        if (company_id != null && multicompany != null && multicompany != 1) {
            companyRequest.company_id = company_id;
        }
        return this.ps.post(this.baseUrl + "getCompany", companyRequest);
    }

    getCompanyAll() {
        let companyRequest: CompanyRequest = new CompanyRequest();
        return this.ps.post(this.baseUrl + "getCompany", companyRequest);
    }


    getProductByName(req: ProductFilterRequest) {
        return this.ps.post(this.baseUrl + "getProductByName", req);
    }

    getProductCategByName(req: ProductCategFilterRequest) {
        return this.ps.post(this.baseUrl + "getProductCategByName", req);
    }

    getProductCategGroupCode() {
        return this.ps.post(this.baseUrl + "getProductCategGroup", {});
    }


    getProductCategAccByName(req: ProductCategFilterRequest) {
        return this.ps.post(this.baseUrl + "getProductCategAccByName", req);
    }

    getProductByFilter(req: ProductRequest) {
        return this.ps.post(this.baseUrl + "getProductByFilter", req);
    }

    getUOM() {
        return this.ps.post(this.baseUrl + "UOM", {});
    }


    getProductCategAll() {
        return this.ps.post(this.baseUrl + "getProductCategAll", {});
    }


    getProductBrandAll() {
        return this.ps.post(this.baseUrl + "getProductBrandAll", {});
    }

    getColorAll() {
        return this.ps.post(this.baseUrl + "getColorAll", {});
    }

    getProductGroupAll() {
        return this.ps.post(this.baseUrl + "getProductGroupAll", {});
    }

    getSizeAll() {
        return this.ps.post(this.baseUrl + "getSizeAll", {});
    }

    getAccountAll() {
        return this.ps.post(this.baseUrl + "getAccountAll", {});
    }

    getAccountTaxRateAll() {
        return this.ps.post(this.baseUrl + "getAccountTaxRateAll", {});
    }

    sendToNF(req: NfApiRequest) {
        return this.ps.post(this.posApi, req);
    }


    getProductIdByCodes(req: ProductIdsRequest) {
        return this.ps.post(this.baseUrl + "getProductIdByCodes", req);
    }


    getUserByUsername(req: UserRequest) {
        return this.ps.post(this.baseUrl + "getUserByUsername", req);
    }

    getProductBySO(req: SoPrintRequest) {
        return this.ps.post(this.baseUrl + "getProductBySO", req);
    }

    getNFLogin(username: string, password: string) {
        let req: NfApiRequest = new NfApiRequest();
        req.id = 1234;
        req.method = "execute";
        req.params = [];
        req.params.push("hawk.posapi");
        req.params.push("get_login");
        req.params.push([{ "username": username, "password": password }]);
        req.params.push({});
        return this.ps.post(this.posApi, req);
    }

    getNFLogin2(username: string, password: string) {
        let req: NfApiRequest = new NfApiRequest();
        req.id = 1234;
        req.method = "execute";
        req.params = [];
        req.params.push("login");
        req.params.push("login");
        req.params.push([]);

        let context = {
            "context": {
                "data": {
                    "db_name": this.gd.dbName,
                    "show_dbs": false,
                    "login": username,
                    "password": password
                }
            }
        };

        req.params.push(context);

        return this.ps.post(this.posApi, req);
    }


    getUserByCompanyId(req: getUsernameByCompanyIdRequest) {
        return this.ps.post(this.baseUrl + "getUserByCompanyId", req);
    }

    getContactFilter(req: any) {
        return this.ps.post(this.autocompleteApi + "getContactFilter", req);
    }

    getEmployeeFilter(req: any) {
        return this.ps.post(this.autocompleteApi + "getEmployeeFilter", req);
    }

    getLocationFilter(req: any) {
        return this.ps.post(this.autocompleteApi + "getLocationFilter", req);
    }

    getProductFilter(req: any) {
        return this.ps.post(this.autocompleteApi + "getProductFilter", req);
    }


    getPurchaseFilter(req: any) {
        return this.ps.post(this.autocompleteApi + "getPurchaseOrderFilter", req);
    }

    getUnitFilter() {
        return this.ps.post(this.autocompleteApi + "getUnitFilter", {});
    }
    getUserAll() {
      return this.ps.post(this.baseUrl + "getUserAll", {});
    }
    getPermisionMain(req:any) {
    return this.ps.post(this.baseUrl + "getPermisionMain", req);
    }
    getPermision(req: any) {
      return this.ps.post(this.baseUrl + "getPermision", req);
    }
    getAction_url(req: any) {
      return this.ps.post(this.baseUrl + "getAction_url", req);
    }
    getCategoryForApp() {
      return this.ps.post(this.mobileUrl + "getCategoryForApp", {});
    }
    saveBillOffline(req: OfflineSaveBillRequest) {
        return this.ps.post(this.offlineUrl + "savingBill", req);
    } 
    saveBillOfflineWithNumber(req: OfflineSaveBillWithNumberRequest) {
        return this.ps.post(this.offlineUrl + "savingBillWithNumber", req);
    }   
    getOfflineCompany() {
        return this.ps.post(this.offlineUrl + "getOfflineCompany", {});
    }
    getOfflineCountBillToday() {
        return this.ps.post(this.offlineUrl + "getOfflineCountBillToday", {});
    }
    getbillDetailbyDateOffline(req: BillDetailDailyReportRequest) {
        return this.ps.post(this.offlineUrl + "getbillDetailbyDateOffline", req);
    }
    SaveVoidRequestOffline(req: SaveVoidRequestRequest) {
        return this.ps.post(this.offlineUrl + "SaveVoidRequest", req);
    }
    getbillDetailbyDateOffline2(req: BillDetailDailyReportRequest) {
        return this.ps.post(this.offlineUrl + "getbillIvDetailbyDateOffline2", req);
    }  
    getbillDetailbyDateOffline3(req: BillDetailDailyReportRequest) {
        return this.ps.post(this.offlineUrl + "getbillIvDetailbyDateOffline3", req);
    }   
    ApproveVoidCredit(req: NfApiRequest) {
        return this.ps.post(this.posApi, req);
    }
    getcreate_so_task(req: CreateSoTaskRequest) {
        return this.ps.post(this.offlineUrl + "create_so_task", req);
    }
    SaveDoneState(req: SaveDoneStateRequest) {
        return this.ps.post(this.offlineUrl + "SaveDoneState", req);
    }
    getProductNF() {
        return this.ps.post(this.offlineUrl + "getProductNF", {});
    }
    getOfflineTbluser() {
        return this.ps.post(this.offlineUrl + "getOfflineUser", {});
    }
}
