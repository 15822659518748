import {Component, OnChanges, OnInit, AfterContentChecked, ViewChild, ElementRef, Output} from '@angular/core';
import {GlobalDataService} from '../../globaldata.service';
import {Router} from '@angular/router';
import * as _ from 'underscore';
import {OfflineSaveBillWithNumberRequest, OfflineSaveBillRequest, Orderd, NfApiRequest, OrderLine} from '../../services/BaseObject';
import {BaseService} from '../../services/Baseservice';

@Component({
    selector: 'probiz-list',
    styleUrls: ['./list.component.scss'],
    templateUrl: './list.component.html',
    providers: []
})
export class ListComponent implements OnInit, AfterContentChecked {
    data: any[];
    selecteds: any[];
    counter = 0;
    name = '';
    total_net = 0;
    displayPaymentMethod: boolean = false;
    gotMoney: number = 0;
    saving = false;
    lines: Orderd[] = []
    rowData: Orderd[];
    payment_method_id:number=null;
    sale_method_id:number=null;
    PaymentMethod: any[];
    selectedPaymentMethod: any;
    SaleMethod: any[];
    selectedSaleMethod: any;
    @ViewChild("PaymentModal") PaymentModal: any;
    @ViewChild('txtgotMoney') txtgotMoney: any;
    @ViewChild('tbReceipt') tbReceipt: ElementRef;

    //    @ViewChild("payment") Payment: PaymentTemplateComponent;
    constructor(private gd: GlobalDataService, private router: Router, private posService: BaseService) {
        this.PaymentMethod = [
            {name: '', code: null},
            {name: 'เงินสด', code: '18'},
            {name: 'เงินโอน', code: '19'},
            {name: 'คูปอง', code: '20'},
            {name: 'Grab', code: '21'},
        ];
        this.SaleMethod = [
            {name: '', code: null},
            {name: 'ลูกค้าทั่วไป', code: '1'},
            {name: 'พนักงาน', code: '2'},
        ];
    }
    ngOnInit() {
        this.data = [];
        this.data.push({product_id: null, qty: null})
        this.gd.listProduct = JSON.parse(this.gd.getStoreItem("listProduct"));
        this.data = this.gd.listProduct;
        if (!this.data) {
            return
        }
        console.log(this.data);
        this.total_net = _.reduce(this.data, function (memo, objqty) {return memo + Number(objqty.total);}, Number(0));

    }
    ngOnDestroy() {

    }
    //ngOnChanges() {
    //  console.log(`ngOnChanges - counter is ${this.counter}`);
    //}
    //ngDoCheck() {
    //  console.log('ngDoCheck');
    //}
    //ngAfterContentInit() {
    //  console.log('ngAfterContentInit');
    //}
    ngAfterContentChecked() {
        this.data = [];
        this.gd.listProduct = JSON.parse(this.gd.getStoreItem("listProduct"));
        this.data = this.gd.listProduct;
        this.total_net = _.reduce(this.data, function (memo, objqty) {return memo + Number(objqty.total);}, Number(0));
    }
    //ngAfterViewInit() {
    //  console.log('ngAfterViewInit');
    //}
    //ngAfterViewChecked() {
    //  console.log('ngAfterViewChecked');
    //}
    //increase() {
    //  this.counter++;
    //}
    //decrease() {
    //  //this.counter--;
    //}
    setDataGT() {
        //console.log(this.data);
        //console.log(this.selecteds);
        this.gd.selecteds = this.selecteds;
        this.router.navigate(['/Probiz/stock/gt', {create: 'gt'}]);
    }
    deleterow(index: any) {
        console.log(index);
        this.data.splice(index, 1);
        this.gd.deleteStoreItem("listProduct");
        this.gd.setStoreItem("listProduct", JSON.stringify(this.data));
        this.total_net = _.reduce(this.data, function (memo, objqty) {return memo + Number(objqty.total);}, Number(0));
    }
    select(event){
        if (this.selectedPaymentMethod != null && this.selectedPaymentMethod != '' && this.selectedPaymentMethod != undefined) {
            this.payment_method_id = this.selectedPaymentMethod.code;
        }else{
            this.payment_method_id = null;
        }
        if (this.selectedSaleMethod != null && this.selectedSaleMethod != '' && this.selectedSaleMethod != undefined) {
            this.sale_method_id = this.selectedSaleMethod.code;
        }else{
            this.sale_method_id = null;
        }
    }
    savePOS() {
        if (this.gotMoney == null) {
            this.gd.showMSG("error", "เกิดข้อผิดพลาด", "กรุณาใส่จำนวนเงิน");
            return;
        }
        if (this.saving) {
            return;
        }

        this.saving = true;
        this.gd.showMSG("info", "บันทึกข้อมูล", "กำลังบันทึกข้อมูล");

        try {

            let req: NfApiRequest = new NfApiRequest();
            req.id = (new Date()).getTime();
            req.method = "execute";
            req.params = [];
            req.params.push("hawk.posapi");
            req.params.push("create_so_offline");
            this.lines = [];

            let o: OrderLine = new OrderLine();
            let lines: any[] = [];

            let pcodes: string[] = [];
            for (let r of this.data) {
                let txt = "";
//                if (r.whipping.length > 0 || r.selectedCities.length > 0 || r.additional.length > 0) {
//                    txt += "ราคา " + Number(r.price) + "฿ ,";
//                }
//                if (r.whipping > 0) {
//                    txt += "Whipping +" + Number(r.whipping) + "฿ ,";
//                }
//                if (r.additional > 0) {
//                    txt += "Cheese +" + Number(r.additional) + "฿ ,";
//                }
//
//                if (r.selectedCities.length > 0) {
//                    for (let c of r.selectedCities) {
//                        txt += "- Syrup " + c + " +5฿ ,";
//                    }
//                }
                if (r.product_price1 != r.price) {
                    txt += "** ราคาสินค้า " + r.product_price1 + "฿";
                    txt += "** ราคาขาย " + r.price + "฿";
                    txt += "** ได้รับส่วนลด " + (r.product_price1- r.price) + "฿";
                }
                if (r.othertxt != '') {
                    txt += "** " + r.othertxt + "";
                }
//              let unit_price = Number(r.total)/Number(r.qty);
//                lines.push(["create", {
//                    product_id: r.product_id, description: txt, unit_price: this.convert(unit_price), qty: r.qty, uom_id: r.uom_id,
//                    location_id: 29, discount_amount: 0, tax_id: 75, discount_per_unit: 0,
//                    final_discount: 0, is_warehouse: false
//                }]);
                let discount  = Number(r.product_price1)-Number(r.price);
                let txt2 = "";
                if(txt == ""){
                    txt2 = "/";
                }else{
                    txt2 = txt;
                }
                lines.push(["create", {
                    product_id: r.product_id, description: txt2, unit_price: this.convert(r.product_price1), qty: r.qty, uom_id: r.uom_id,
                    location_id: 29, discount_amount: this.convert(discount*r.qty), tax_id: 75, discount_per_unit: this.convert(discount),
                    final_discount: this.convert(discount*r.qty), is_warehouse: false
                }]);
                let l = _.clone(r);
                this.lines.push(l);
            }

            o.company_id = 4; //บริษัท ทีอาร์ที เชน คอร์ปอเรชั่น จำกัด
            o.contact_id = 335241; //None ลูกค้าทั่วไป
            o.ref = "POS OFFLINE";
            o.payment_method_id = this.payment_method_id;//เงินสด/เงินโอน
            o.sale_method_id = this.sale_method_id;//พนักงาน/ลูกค้าทั่วไป
            o.payment_type = "cash";
            o.date = this.gd.getNowDateFormat2();
            o.due_date = this.gd.getNowDateFormat2();
            o.lines = lines;
            o.register_id = 62; //C1
            //thai baht
            o.currency_id = 3;
            o.currency_rates = [];
            o.currency_rates.push(["create", {currency_id: 3, rate: 1.0}]);
            if(localStorage.getItem("store_type") == 'ร้านกาแฟ'){
            o.type = "sale_retail_cash";
            }else if(localStorage.getItem("store_type") == 'ร้านอาหาร'){
            o.type = "sale_wholesale_cash";
            }
            
            o.user_id = Number(this.gd.getCookie("tbluser_id"));
            o.gotmoney = this.gotMoney;
            o.changemoney = this.gotMoney - this.total_net;
            o.discount_amount_total = 0;
            o.other_info = "";
            o.sequence_id = null;
            o.other_info_id = null;
            o.seller_id = null;

            //            o.score_cm1 = 0;
            //            o.score_cm3 = 0;
            //            o.score_total = 0;
            //            o.amount_cm1 = 0;
            //            o.amount_cm3 = 0;
            o.tax_type = null;
            o.shipping_address = null;
            o.bill_address_id = null;
            o.ship_address_id = null;
            o.price_list_id = 197; //ราคา 1 - สำนักงานใหญ่

            let args: any = {
                'orders': [o],

            }

            req.params.push([args]);
            req.params.push({});

            let reqSave = new OfflineSaveBillRequest();
            reqSave.currency_id = (new Date()).getTime();
            reqSave.company_id = Number(this.gd.getCookie("company_id"));
            reqSave.company_code = "00000";
            reqSave.user_id = Number(this.gd.getCookie("tbluser_id"));
            reqSave.type = "sale_retail_cash";
            reqSave.contact_id = 335241; //None ลูกค้าทั่วไป
            reqSave.contact_name = "[None] ลูกค้าทั่วไป";
            reqSave.amount_total = this.total_net;

            if (Number(this.gd.getCookie("company_id")) == null) {
                this.gd.showMSG("error", "เกิดข้อผิดพลาด", "กรุณาออกจากระบบ แล้ว Login ใหม่");
                return;
            }
            if(localStorage.getItem("store_type") == 'ร้านกาแฟ'){
            reqSave.offline_sequence_code = 'A00';
            }else if(localStorage.getItem("store_type") == 'ร้านอาหาร'){
            reqSave.offline_sequence_code = 'IC00';
            }
            reqSave.sale_data2 = JSON.stringify(req);
            //reqSave.sale_data = JSON.stringify(req);
            //            console.log(JSON.stringify(reqSave));
            //                        console.log(JSON.stringify(req));
            this.posService.saveBillOffline(reqSave).then(data => {
                if (data.code == "200") {
                    this.gd.showMSG("success", "สำเร็จ", "บันทึกข้อมูลสำเร็จ เลขที่ " + data.order_no + "");
                    let reqSaveWithNumber = new OfflineSaveBillWithNumberRequest();
                    reqSaveWithNumber.number = data.order_no;
                    req.params[2]["0"].orders["0"].number = data.order_no;
                    reqSaveWithNumber.sale_data = JSON.stringify(req);
                    this.posService.saveBillOfflineWithNumber(reqSaveWithNumber).then(resData => {

                        this.saving = false;
                        this.PaymentModal.hide()
                        this.printPOS(data.order_no);
                    });
                }
            });
        }
        catch (e) {
            this.saving = false;
            this.gd.showMSG("error", "เกิดข้อผิดพลาด", e);
        }
    }
    setStoreItem(key: string, value: string) {
        localStorage.setItem(key, value);
    }
    convert(data: any) {
        try {
            let price = this.gd.numberWithCommas0(data);
            return price;
        } catch (e) {
            console.log(e);
            return data;
        }
    }


    printPOS(billNumber: string) {
        this.posService.getOfflineCountBillToday().then(data => {
            let sumTotal = this.gd.numberWithCommas(this.total_net);
            let sumGotMoney = this.gd.numberWithCommas(this.gotMoney);
            let sumChangeMoney = this.gd.numberWithCommas(this.gotMoney - this.total_net);

            let currentDate = this.gd.getNowDateFormat();
            let companyName = this.gd.shareObj['main'].getCompanyName();
            let companyAddress = this.gd.shareObj['main'].getCompanyAddress();
            let phone = this.gd.shareObj['main'].getCompanyPhone();
            let fax = this.gd.shareObj['main'].getCompanyFax();
            let user = this.gd.getCookie("user_name");

            let styleFooter = 'style="width:95%;"';
            if (this.lines.length > 12) {
                styleFooter = "";
            }

            let printContents = $(this.tbReceipt.nativeElement).html(); //ความร้อน ใบเสร็จ
            let template = `
<html>

<head>
<meta http-equiv="Content-Type" content="text/html; charset=windows-874">
<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
<meta http-equiv="Content-Type" content="text/html; charset=tis - 620">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<link href='https://fonts.googleapis.com/css?family=Kanit:400,300&subset=thai,latin' rel='stylesheet' type='text/css'>
<style>
<!--
 /* Font Definitions */
 body{
        font-family: 'Kanit', sans-serif;
     }
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	font-family: 'Kanit', sans-serif;
.MsoChpDefault
	{font-family: 'Kanit', sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:297.65pt 419.55pt;
	margin:.1in .1in .1in .1in;}
div.WordSection1
	{page:WordSection1;}
-->

@page {
   size: 4.13in 5.83in;
  margin:.1in .1in .1in .1in;}
}
@media all {
	.page-break	{ display: none; }
}

@media print {
	.page-break	{ display: block; page-break-before: always; }
}
</style>

</head>

<body lang=EN-US  onload="window.print();window.close()">

<div class=WordSection1>

<h2 class=MsoNormal style='text-align:center;margin-bottom:0in;margin-bottom:.0001pt;line-height:
normal'>ออเดอร์ที่ No ${data[0].countid}</h2>
<p class=MsoNormal style='text-align:center;margin-bottom:0in;margin-bottom:.0001pt;line-height:
normal'><img height=55 src="assets/TRTC-Logo.png" align=center
hspace=12></p>
<p class="MsoNormal" style='text-align:center;margin-bottom:0in;margin-bottom:.0001pt;line-height:
normal'><span lang=TH style='font-size:16px;'>ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ</span></p>
<p class"=MsoNormal" style='text-align:center;margin-bottom:0in;margin-bottom:.0001pt;line-height:
normal'><span lang=TH style='font-size:16px;'>Buff Cafe</span></p>
<p class="MsoNormal" style='text-align:center;margin-bottom:0in;margin-bottom:.0001pt;line-height:
normal'><span lang=TH style='font-size:12px;'>${companyAddress}</span></p>

<table style='width:95%;font-size:10px;font-family: 'Kanit', sans-serif;'>
<tr>
<td style="text-align:center;">โทร : 043 001798-9</td>
<td style="text-align:center;">แฟกซ์ : 043 001788</td>
</tr>
<tr>
<td style="text-align:center;">Tax ID : 0405558000224</td>
<td style="text-align:center;">พนักงานขาย : ${user}</td>
</tr>
<tr>
<td>${billNumber}</td>
<td>${currentDate}</td>
</tr>
<tr>
<td colspan=2 style='font-size:18px;'></td>
</tr>
</table>

<table style='width:95%;font-size:12px;font-family: 'Kanit', sans-serif;'>
 ${printContents}
</table>
<table style='width:95%;font-size:12px;font-family: 'Kanit', sans-serif;'>
    <tr>
        <td>รวมเป็นเงิน :</td>
        <td style="text-align:right">${sumTotal}</td>
    </tr>
     <tr>
        <td>รับเงิน :</td>
        <td style="text-align:right">${sumGotMoney}</td>
    </tr>
    <tr>
        <td>เงินทอน :</td>
        <td style="text-align:right">${sumChangeMoney}</td>
    </tr>
    <tr><th colspan=2 style='border-bottom: 2px dotted black;'></th></tr>
</table>
<p class=MsoNormal style='text-align:center;margin-bottom:0in;margin-bottom:.0001pt;line-height:
normal'><span lang=TH style='font-size:12px;'>ขอบคุณที่ใช้บริการ Buff Cafe</span></p>
</div>
</body>

</html>
`;


            //let printContents, popupWin;
            //printContents = document.getElementById('print-section').innerHTML;
            let popupWin = window.open('', 'PRINT', 'top=0,left=0,height=100%,width=auto');
            popupWin.document.open();
            popupWin.document.write(template);
            popupWin.document.close();
            this.gotMoney = 0;
            this.gd.deleteStoreItem("listProduct");
        });
    }

    test() {
          setTimeout(() => this.txtgotMoney.nativeElement.focus(), this.txtgotMoney.nativeElement.select(), 1000);
    }
    enterAsTab(e: KeyboardEvent) {
        if ((e.which == 13 || e.keyCode == 13)) {
            e.preventDefault();
            var tabindex = $(e.srcElement).attr('tabindex');
            tabindex++; //increment tabindex
            $('[tabindex=' + tabindex + ']').focus();
            $('[tabindex=' + tabindex + ']').select();
        }
    }
    SelectedPaymentMethod(numberx: number) {
        this.payment_method_id = numberx;
    }
}
