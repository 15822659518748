import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ListComponent } from './list.component';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {RadioButtonModule} from 'primeng/radiobutton';

const routes: Routes = [{
  path: 'main',
  component: ListComponent
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RadioButtonModule,RouterModule,ScrollPanelModule],
})
export class ListRoutingModule {

}
