import { Component, ViewChild, Output, EventEmitter, Input, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { GlobalDataService } from '../../globaldata.service';
import { OdooRPCService } from '../../services/OdoorpcService';
import { OdooMasterService } from '../../services/NFMasterService';
import { PrintingTemplate } from '../../services/BaseObject';
import { JsonRPCRequest } from '../../services/NfObject';

declare let cordova: any;
declare let x_tion: any;

@Component({
    templateUrl: 'printing-template.component.html',
    selector: 'printing-template',
    providers: [OdooRPCService, OdooMasterService]
})

export class PrintingTemplateComponent {

    filteredPrintingTemplates: PrintingTemplate[];
    @Input() printingTemplate: PrintingTemplate = new PrintingTemplate();
    @Input() template_type = "";
    @Input() model = "";
    @Input() id: number;
    format: string;
    displayField = "name";
    @ViewChild("printModal") printModal: any;

    constructor(private gd: GlobalDataService, private cd: ChangeDetectorRef, private odooMasterService: OdooMasterService) {
        this.format = "pdf";
        this.filteredPrintingTemplates = [];
    }

    ngOnInit() {

    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes['template_type']) {
            this.getTemplate();
        }
    }

    getTemplate() {
        let that = this;
        let reportTermplateCondition = [["type", "=", this.template_type]];
        this.odooMasterService.getReportTemplate(reportTermplateCondition).then(res => {
            let printingTemplates: PrintingTemplate[] = [];
            for (let r of res) {
                printingTemplates.push(Object.assign(new PrintingTemplate(), { id: r[0], name: r[1] }));
            }
            that.filteredPrintingTemplates = printingTemplates;
            if (that.filteredPrintingTemplates.length > 0) {
                that.printingTemplate = that.filteredPrintingTemplates[0];
            }
        });
    }

    ngAfterViewInit() {

    }


    ngOnDestroy() {

    }

    selectRow(event: any) {

    }



    print() {
        if (!this.printingTemplate || !this.printingTemplate.name) {
            return;
        }
        let URLQuery = "convert=+" + this.format + "+&template=" + this.printingTemplate.name + "&ids=[" + this.id + "]&model=" + this.model + "&type=report_jasper";
        URLQuery = encodeURI(URLQuery);
        window.open("https://pwc.netforce.co.th/report?" + URLQuery, "_system");
    }

    showPrint() {
        if (!this.filteredPrintingTemplates || this.filteredPrintingTemplates.length == 0) {
            this.getTemplate();
        }
        this.printModal.show();
    }

}
